// client/src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { SIGNUP_API_URL }  from '../config/api-config'
import {ThreeDots} from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";

const Register = ({ handleOnClick })  => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const handleSignup = async (e) => {
    // Add login logic here (communicate with your server)
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(SIGNUP_API_URL, {
        email,
        password,
        name,
      });
      if(response.status === 200){
        if(response.data.status === 1){
          const token = response.data.data.token;
          const type = response.data.data.type;
          const name = response.data.data.name;
          const email = response.data.data.email;
          await localStorage.setItem('token', token);
          await localStorage.setItem('email', email);
          await localStorage.setItem('type', type);
          await localStorage.setItem('name', name);
          navigate("/");

        }else{
          setLoading(false);
          alert(response.data.message)
        }
      }else{
        setLoading(false);
        alert("Error in Signing up , Please try again")
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error('Error signing up:', error.response.data);
    }
  };

  return (
    <div className="form-container sign-in-container">
      
      <form onSubmit={handleSignup}>
      <img className="auth_images" alt="logo" src="./././login.png" />
        <p className='title_class'>Sign Up</p>
        <input
          type="text"
          name="name"
          required
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          name="password"
          required
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        
        <span className="auth_texts"
          id="signUp"
          onClick={() => handleOnClick('login')}
        >
         Already have an account ? Login !
        </span>
        {/* <a href="#">Forgot your password?</a> */}
        <button type="submit" disabled={loading}>
          {loading ? (
            <ThreeDots  color="#000000" height={30} width={30} />
          ) : (
            'Register'
          )}
        </button>
      </form>
    </div>
  );
}

export default Register;
