// client/src/App.js
import React, { useState, useEffect } from 'react';
import Login from './Login';
import Register from './Register';
import { useNavigate } from "react-router-dom";
import Footermain from './Footermain'; 
// import Header from './Header';

const Auth = () => {
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");

  const navigate = useNavigate();

  useEffect(() => {
    if( token && token.length > 0){
      if(type == "admin"){
        navigate("/homeadmin");
      }else{
        navigate("/");
      } 
    }
  }, []);

  const [currentComponent, setCurrentComponent] = useState('login');
  const [buttonText, setButtonText] = useState('Sign Up');
  
  const handleOnClickLogo = () => {
    navigate("/");
  }
  const handleOnClick = (componentType) => {
    setButtonText(componentType === 'login' ? 'Sign Up' : 'Sign In');
    setCurrentComponent(componentType);
  };
  return (
   <div>
       {/* <Header /> */}
  
    {/* <div> */}
      
         {/* <div className="overlay-panel overlay-left"> */}
         {/* <div className="left-side"> */}
          
      {currentComponent === 'login' ? (
        <Login handleOnClick={handleOnClick} />
      ) : (
        <Register handleOnClick={handleOnClick} />
      )}
       {/* </div> */}
       
{/* </div> */}

      <div className="overlay-panel overlay-right">
      {/* <p className='title_class'>Contractor Fax</p> */}
      <img onClick={() => handleOnClickLogo()} className="process_images_logo" alt="logo" src="./././weblogoblue.png" />

      <p></p>
      <img className="process_images_process" alt="logo" src="./././mainprocess.png" />
            
              <p>Enter your personal details and start exploring the information</p>
              <button
          id="signUp"
          onClick={() => handleOnClick(currentComponent === 'login' ? 'signup' : 'login')}
        >
          {buttonText}
        </button>
            </div>
            {/* <Footermain /> */}
    {/* </div> */}
    </div>
  );
};

export default Auth;
