import React, { useState, useEffect } from 'react';
import Header from './Header';
import axios from 'axios';
import { ADD_FAX_REQUESTS }  from '../config/api-config'
import {Bars} from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  const [email, setEmailName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const type = localStorage.getItem("type");

  const token = localStorage.getItem("token");
  
  const handleAddRequest = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // await new Promise(resolve => setTimeout(resolve, 3000));

      const response = await axios.post(ADD_FAX_REQUESTS, {
        'contractor_name':email,
        'message':message,
        'subject':subject
      },
      {
        headers: {
          'authorization': token // Adjust content type as needed
        },
     
    });
      if(response.status === 200){
        if(response.data.status === 1){
          alert(response.data.message);
          navigate("/");
        }else if(response.data.status === 10){
          setLoading(false);
          alert(response.data.message)
          navigate("/auth");
        }else{
          setLoading(false);
          alert(response.data.message)
        }
      }else{
        setLoading(false);
        alert("Error in Logging in , Please try again")
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error('Error signing up:', error.response.data);
    }finally{
      setLoading(false);
    }
  };
  useEffect(() => {
    if(!token || token.length === 0){
      // navigate("/");
      }
  }, );
  return (
    <div>
   <Header />
    <div className='contentContainer' style={{ display: 'flex', flexDirection: 'column',alignItems: 'center', marginTop: '84px'}}>
    <div className="process_images_container" style={{backgroundImage:`url('./././faq.png')`, height:'300px', backgroundSize:'contain',backgroundRepeat: 'no-repeat',
    backgroundPosition:'right'}} >
    {/* <img className="process_images_inner_first"  style={{ height:'250px'}} alt="logo" src="./././g10.png" /> */}

   
    {/* <div className="banner_images"> */}
    <div className='bannerdescription'>
        <h3 className='banner_titles_small'>About Us</h3>
    <h1 className="banner_titles_large">Streamline Your Contractor . </h1>
     <h1 className="banner_titles_large"> Management, Comprehensive Results</h1>
   
    </div>
    {/* <img className="process_images_inner"  style={{ height:'300px' }} alt="logo" src="./././1122.png" /> */}
    {/* </div> */}
    </div>
    
    <div className='faq-section'>
    <h2 style={{textAlign:'center'}}>Unlock Contractor Insights: Your Questions, Our Answers</h2>
          <ul className='faq-section__content'>
         
            <li>
              <div className='gridItem'>
                <h3>How can Contractor-Fax help me access contractor information conveniently??</h3>
                <p>Contractor-Fax provides a user-friendly interface with various search options and refined parameters, making it easy to access contractor information. Whether you need to search by name, location, or specific criteria, our platform ensures thorough results that cater to your specific needs.</p>
              </div>
            </li>
            <li>
            <div className='gridItem'>
                <h3>What kind of information can I expect from the detailed PDF reports provided by Contractor-Fax?</h3>
                <p>Our detailed PDF reports contain essential information such as document numbers and personal details of contractors. These reports are meticulously compiled to offer comprehensive insights into contractor backgrounds, ensuring that you have all the information you need for your projects or business dealings.</p>
              </div>
            </li>
            <li>
            <div className='gridItem'>
                <h3>How secure are payments made on the Contractor-Fax website?</h3>
                <p>At Contractor-Fax, we prioritize the security of our users' payments. We utilize industry-standard encryption and security measures to safeguard your payment information. When you make a payment on our website to access detailed reports, you can rest assured that your transaction is secure and your sensitive data is protected.</p>
              </div>
            </li>
          </ul>
    </div>
    </div>
    </div>
  );
}

export default AboutUs;
