// client/src/App.js
import React from 'react';
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";
import Auth from './components/Auth';
import Home from './components/Home'; // Import the Home component
import Footermain from './components/Footermain'; // Import the Footer component
import './App.css'; // You can import any global styles if needed
import AddRequest from './components/Addrequest';
import RequestDetail from './components/RequestDetail';
import AdminHome from './components/AdminHome';
import SendOtp from './components/sendOtp';
import VerifyOtp from './components/verifyOtp';
import ChangePassword from './components/changePassword';
import ContactUs from './components/contactus';
import PrivacyPolicy from './components/privacypolicy';
import TermsCondition from './components/termsCondition';
import AboutUs from './components/aboutus';
import ConfirmPayment from './components/paymentconfirm';
import SuccessPayment from './components/paymentsuccess';
import Profile from './components/profile';
const App = () => {
  const token = localStorage.getItem('accessToken');

  return (
    <div className="app-container">
    <Router>
      <Routes>
          <Route path="/" Component={Home} />
          <Route path="/addrequest" Component={AddRequest} />
          <Route path="/requestDetail" Component={RequestDetail} />
          <Route path="/homeadmin" Component={AdminHome} />
          <Route path="/forgot_password" Component={SendOtp} />
          <Route path="/verify_otp" Component={VerifyOtp} />
          <Route path="/reset_password" Component={ChangePassword} />
          <Route path="/auth" Component={Auth} />
          <Route path="/contactus" Component={ContactUs} />
          <Route path="/privacypolicy" Component={PrivacyPolicy} />
          <Route path="/terms-condition" Component={TermsCondition} />
          <Route path="/about-us" Component={AboutUs} />
          <Route path="/payment-confirm" Component={ConfirmPayment} />
          <Route path="/payment-success" Component={SuccessPayment} />
          <Route path="/profile" Component={Profile} />
        </Routes>
        <Footermain />
    </Router>
  
      {/* Include the Footer component */}
     
    </div>
  );
};

export default App;
