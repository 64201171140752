import React, { useState, useEffect } from 'react';
import Header from './Header';
import axios from 'axios';
import { ADD_FAX_REQUESTS, CONTACT_ADMIN }  from '../config/api-config'
import {Bars} from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  const [email, setEmailName] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const type = localStorage.getItem("type");

  const token = localStorage.getItem("token");
  
  const handleAddRequest = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // await new Promise(resolve => setTimeout(resolve, 3000));

      const response = await axios.post(CONTACT_ADMIN, {
        'name':name,
        'email':email,
        'message':message,
        'subject':subject,

      },
      {
        headers: {
          'authorization': token 
        },
     
    });
      if(response.status === 200){
        if(response.data.status === 1){
          alert(response.data.message);
          navigate("/");
        }else if(response.data.status === 10){
          setLoading(false);
          alert(response.data.message)
          navigate("/auth");
        }else{
          setLoading(false);
          alert(response.data.message)
        }
      }else{
        setLoading(false);
        alert("Error in Logging in , Please try again")
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error('Error signing up:', error.response.data);
    }finally{
      setLoading(false);
    }
  };
  useEffect(() => {
    if(!token || token.length === 0){
      // navigate("/");
      }
  }, );
  return (
    <div>
   <Header />
    <div className='contentContainer' style={{ display: 'flex', flexDirection: 'column',alignItems: 'center', marginTop: '84px'}}>
    <div className="process_images_container" style={{backgroundImage:`url('./././backgroundone.png')`, height:'300px', backgroundSize:'cover'}} >
    {/* <img className="process_images_inner_first"  style={{ height:'250px'}} alt="logo" src="./././g10.png" /> */}

   
    {/* <div className="banner_images"> */}
    <div className='bannerdescription'><h3 className='banner_titles_small'>Contact Us</h3>
    <h1 className="banner_titles_large">Got questions? We've got answers. </h1>
    <h1 className="banner_titles_large"> Contact us for assistance!</h1>
    <p className="banner_text">We're here to help!</p>
    </div>
    {/* <img className="process_images_inner"  style={{ height:'300px' }} alt="logo" src="./././1122.png" /> */}
    {/* </div> */}
    </div>
    <div className="addRequestFormConatiner" style={{ width:'70%', marginTop: '-34px', marginBottom: '30px'}}>
      <form onSubmit={handleAddRequest}>
        <h3 className="searchTitle">Contact Us</h3>
        <input
          type="email"
          placeholder="Your Email"
          name="email"
          required
          value={email}
          onChange={(e) => setEmailName(e.target.value)}
        />
           <input
          type="text"
          placeholder="Your name"
          name="name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <textarea
        name="message"
        style={{width:'100%', marginTop:'8px',border: '1px #929292 solid',
        borderRadius: '10px' , backgroundColor:'#eee', padding:'12px 15px', height:'200px'
        }}
        placeholder="Your message..."
        required
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        ></textarea>


{/* <button type="submit" disabled={loading}> */}
<button type="submit">

          {loading ? (
            <Bars  color="#ffffff" height={20} width={20} />
          ) : (
            'Send message'
          )}
        </button>
      </form>
    </div>
    <div className='faq-section'>
    <h2 style={{textAlign:'center'}}>Unlock Contractor Insights: Your Questions, Our Answers</h2>
          <ul className='faq-section__content'>
         
            <li>
              <div className='gridItem'>
                <h3>How can Contractor-Fax help me access contractor information conveniently??</h3>
                <p>Contractor-Fax provides a user-friendly interface with various search options and refined parameters, making it easy to access contractor information. Whether you need to search by name, location, or specific criteria, our platform ensures thorough results that cater to your specific needs.</p>
              </div>
            </li>
            <li>
            <div className='gridItem'>
                <h3>What kind of information can I expect from the detailed PDF reports provided by Contractor-Fax?</h3>
                <p>Our detailed PDF reports contain essential information such as document numbers and personal details of contractors. These reports are meticulously compiled to offer comprehensive insights into contractor backgrounds, ensuring that you have all the information you need for your projects or business dealings.</p>
              </div>
            </li>
            <li>
            <div className='gridItem'>
                <h3>How secure are payments made on the Contractor-Fax website?</h3>
                <p>At Contractor-Fax, we prioritize the security of our users' payments. We utilize industry-standard encryption and security measures to safeguard your payment information. When you make a payment on our website to access detailed reports, you can rest assured that your transaction is secure and your sensitive data is protected.</p>
              </div>
            </li>
          </ul>
    </div>
    </div>
    </div>
  );
}

export default ContactUs;
