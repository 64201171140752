// Home.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { createSearchParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Header from './Header';
import { GET_FAX_REQUESTS }  from '../config/api-config'
import {Bars} from 'react-loader-spinner';


const Home = () => {
const navigate = useNavigate();
const [tableData, setTableData] = useState([]);
const [loading, setLoading] = useState(false);
const token = localStorage.getItem("token");
const placeholder="---";
// const location = useLocation();
// const { someValue } = location.state || {};

const handleRowClick = (rowId) => {
  navigate("/requestDetail?request_id="+rowId);
  // Add your logic here for what should happen when the row is clicked
};
const handleGetReportsClick = () => {
  if(!token || token.length === 0){
    navigate("/auth");
  }else{
  const element = document.querySelector('.tableContainer');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
};

useEffect(() => {

  if(!token || token.length === 0){
    // navigate("/");
    console.log(token);
  }else{
  const fetchData = async () => {
    setLoading(true);
    try {
      // await new Promise(resolve => setTimeout(resolve, 3000));
    
    
      // console.log(token);
      const response = await axios.get(GET_FAX_REQUESTS, {
        headers: { 
          'authorization': token
        },
      });
      if(response.status === 200){
        if(response.data.status === 1){
          setTableData(response.data.data);
        }else if(response.data.status === 10){
          setLoading(false);
          alert(response.data.message)
          navigate("/");
        }else{
          setLoading(false);
          console.log(response.data.message);
          // alert(response.data.message)
        }
      }else{
        setLoading(false);
        alert("Error in Logging in , Please try again")
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error('Error signing up:', error.response.data);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
  // console.log(someValue);
  // if (someValue === 'move_to_reports') {
  // handleGetReportsClick();
  // }
}
}, []);

  const handleAddRequest = () => {
    // Add logic for handling the addition of a request
    // if(!token || token.length === 0){
    //   navigate("/auth");
    // }else{
      navigate("/addrequest");
    // }
    
  };
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Sample data for the table
  // const tableData = [
  //   { id: 1, title: '#393HJHYT', date: '22 Dec, 2023' },
  //   { id: 2, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 3, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 4, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 5, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 6, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 7, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 8, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 9, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 10, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 11, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 12, title: '#393HJHYT', date: '22 Dec, 2023' },
  //   { id: 13, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 14, title: '#393HJHYT', date: '22 Dec, 2023' },
  //   { id: 15, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   // Add more data as needed
  // ];

  return (
    <div>
      {/* Header */}
      <Header />
    {loading && (
        <div className="loader-container">
         <Bars color="#1976d2"  loading={loading} height="40" width="40"/>
        </div>
      )}
      {/* <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}> */}
      {/* <div style={{ flex: '18%', backgroundColor: '#103559' }}> */}
   
    {/* <SideNav /> */}
  {/* </div> */}
    <div className='contentContainer' style={{flex: '82%', display: 'flex', padding:'10px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
   
      {/* Add Request Button */}
      <div style={{ textAlign: 'center', marginTop: '120px', width:'80%', justifyContent:'center' }}>
      <h1 style={{ lineHeight: 1.5 }}>Are you looking for Contractors ?</h1>
      <h3>Verify who it is working in your home before signing on.....</h3>
        <div style={{ display:'block' }}>
        <button className="buttonsmobile" style={{borderRadius: '5px' , padding: '12px 100px 12px 100px '}} onClick={handleAddRequest}>Add Request <FontAwesomeIcon style={{marginRight: '5px', color: '#ffffff'}} icon={faPlus} />
        </button>
        <span className="ormobile" style={{marginRight: '10px', marginLeft:'10px'}}>OR</span>
        <button className="buttonsmobile" style={{borderRadius: '5px', padding: '12px 100px 12px 100px '}} onClick={handleGetReportsClick}>Get reports
        </button>

    </div>
    <img className="big_images" alt="logo" src="./././main.png" />
    <h1 style={{marginTop:'20px'}}>Find Contactors with ease </h1>
    <div className="company">
    <div className="img">
      <img src="./././searchc.png" alt="" />
    </div>
    <div className="company-info">
      <span>Step 1 - <span> Search Contractor</span></span>
      <p>
      Users can effortlessly locate contractors or businesses by initiating searches based on key criteria such as contractor/business name, license number, city/country, and license type, streamlining the process of finding relevant information.
      </p>
    </div>
  </div>

  <div className="company">
    <div className="company-info">
      <span>Step 2 - <span> Payment Processing:</span></span>
      <p>
      Upon locating desired information, users can securely proceed with payment, gaining access to detailed reports and additional premium features. This payment mechanism ensures that users receive comprehensive data tailored to their needs.
      </p>
    </div>
    <div className="img">
      <img src="./././payment.png" alt="" />
    </div>
  </div>

  <div className="company">
    <div className="img">
      <img src="./././requestsubmitted.png" alt="" />
    </div>
    <div className="company-info">
      <span>Step 3 - <span> Request Submitted</span></span>
      <p>
      Upon submitting your desired request, it will be seamlessly forwarded to the Contractor-Fax team for further evaluation and processing. Our dedicated team will meticulously review your requirements, ensuring that they are accurately understood and addressed.
      </p>
    </div>
  </div>

  <div className="company">
    <div className="company-info">
      <span>Step 4 - <span> Track Request</span></span>
      <p>
      Once your request is submitted, you'll have the capability to track its progress in real-time. Our intuitive platform allows you to monitor each stage of the process, from submission to completion. Additionally, once the information about the contractors you need is available, you can easily download it directly from the platform.
      </p>
    </div>
    <div className="img">
      <img src="./././track.png" alt="" />
    </div>
  </div>
  <div className="info-box">
  <h1 style={{ lineHeight: 1.5 }}>What contractor fax do ?</h1>
        <p className="info-description">
        Contractor-Fax is a convenient solution for accessing contractor information with ease.
         Website will offer a user-friendly interface with various search options and refined parameters, ensuring thorough results that cater to specific needs. 
         By making a secure payment, users can gain access to detailed PDF reports containing essential information such as document numbers and personal details.
        {/* </p>
        <p className="info-description"> */}
        The website will feature an intuitive and easy-to-navigate interface, making it effortless for users to explore and utilize its functionalities. Users will have access to various search options, allowing them to input specific criteria such as contractor names, license numbers, locations, or other relevant parameters. This versatility ensures that users can refine their searches to yield precise and relevant results.
        </p>
      </div>


      {token && token.length > 0 && tableData.length > 0 ? (
  <>
    <div className="tableContainer">
    <h2 style={{ lineHeight: 1.5 }}>Requests</h2>

      <TableContainer component={Paper} style={{ marginTop: '20px', borderRadius: '10px' }}>
        <Table>
          <TableHead>
            <TableRow className='tableRow'>
              <TableCell>Request Number</TableCell>
              <TableCell>Contractor Name</TableCell>
              <TableCell>License Number</TableCell>
              <TableCell>License Type</TableCell>
              <TableCell>City/Country</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : tableData
            ).map((row, index) => (
              <TableRow key={row.id} className="hover-row" onClick={() => handleRowClick(row.id)} style={{ backgroundColor: index % 2 === 0 ? '#e5e5e5' : 'white' }}>
                <TableCell style={{ padding: '5px' }}>#{row.request_number}</TableCell>
                <TableCell style={{ padding: '5px' }}>{row.contractor_name || placeholder}</TableCell>
                <TableCell style={{ padding: '5px' }}>{row.lic_number || placeholder}</TableCell>
                <TableCell style={{ padding: '5px' }}>{row.lic_type || placeholder}</TableCell>
                <TableCell style={{ padding: '5px' }}>{row.city || placeholder}</TableCell>
                <TableCell style={{ padding: '5px' }}>{row.search_status || placeholder}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  </>
) : (
  <>
    <div className="info-box-lower">
     
      <div className="info-box-lower-inside">
      <img style={{height:'500px'}} src="./././carcare.png" alt="" />
     
      <div className="info-description">
      <h1 style={{ lineHeight: 1.5, color:"#000000"}}>No request yet?</h1>
      <button className="buttonsmobile" style={{borderRadius: '2px' , padding: '12px 100px 12px 100px '}} onClick={handleAddRequest}>Add Request <FontAwesomeIcon style={{marginRight: '5px', color: '#ffffff'}} icon={faPlus} />
        </button>      </div>
      </div>
    </div>
  </>
)}
      </div>
        </div>
    </div>
  );
};

export default Home;
