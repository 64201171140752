// const API_BASE_URL = 'http://127.0.0.1:4001'; 
const API_BASE_URL = 'http://154.56.61.220:5000'; 


export const SIGNUP_API_URL = `${API_BASE_URL}/auth/signup`;
export const LOGIN_API_URL = `${API_BASE_URL}/auth/login`;
export const SEND_OTP = `${API_BASE_URL}/auth/sendOtp`;
export const VERIFY_OTP = `${API_BASE_URL}/auth/verifyOtp`;
export const RESET_PASSWORD = `${API_BASE_URL}/auth/resetPassword`;
export const GET_FAX_REQUESTS = `${API_BASE_URL}/fax/getRequests`;
export const GET_FAX_REQUESTS_ADMIN = `${API_BASE_URL}/fax/getRequestsAdmin`;
export const GET_FAX_REQUESTS_DETAIL = `${API_BASE_URL}/fax/getRequestDetail`;
export const ADD_FAX_REQUESTS = `${API_BASE_URL}/fax/addRequest`;
export const ADD_TEMP_REQUESTS = `${API_BASE_URL}/fax/addTemporaryRequest`;
export const PAY_NOW = `${API_BASE_URL}/fax/createpaymentCustomer`;
export const UPLOAD_MEDIA_REQUEST = `${API_BASE_URL}/fax/uploadMediaRequest`;
export const EDIT_FAX_REQUESTS = `${API_BASE_URL}/fax/editRequestsAdmin`;
export const UPLOAD_FILE_DATA = `${API_BASE_URL}/fax/uploadMedia`;
export const GET_CUSTOMER_DATA = `${API_BASE_URL}/fax/getCustomerData`;
export const GET_META_DATA_BY_REQUEST = `${API_BASE_URL}/fax/getCustomerData`;
export const GET_CUSTOMER_DATA_REQUEST = `${API_BASE_URL}/fax/getCustomerIdByRequestNumber`;
export const CHECK_REQUEST_EXISTS = `${API_BASE_URL}/fax/checkRequestExists`;
export const UPDATE_USER_PROFILE = `${API_BASE_URL}/fax/updateUserProfile`;
export const CHANGE_PASSWORD = `${API_BASE_URL}/fax/changePassword`;
export const GET_REQUESTS_STATS = `${API_BASE_URL}/fax/getRequestsStats`;
export const MAIN_SAVE_REQUEST =`${API_BASE_URL}/fax/mainSaveRequest`;
export const CONTACT_ADMIN = `${API_BASE_URL}/fax/contactAdmin`;
export const OTHER_API_URL = `${API_BASE_URL}/api/other`;

export const STRIPEPAYMENT = `${API_BASE_URL}/fax/pay`;