import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import {
  ADD_FAX_REQUESTS,
  CHANGE_PASSWORD,
  UPDATE_USER_PROFILE,
} from "../config/api-config";
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const type = localStorage.getItem("type");
  const email = localStorage.getItem("email");
  const localName = localStorage.getItem("name");
  const token = localStorage.getItem("token");
  const [selectedTab, setSelectedTab] = useState("edit"); // Default to 'edit' tab
  const [name, setName] = useState(localName);
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const handleUpdateProfile = async (e) => {
    console.log(token);
    e.preventDefault();
    setLoading(true);
    console.log(token);
    try {
      // await new Promise(resolve => setTimeout(resolve, 3000));

      const response = await axios.post(
        UPDATE_USER_PROFILE,
        {
          name: name,
        },
        {
          headers: {
            authorization: token,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.status === 1) {
          await localStorage.setItem("name", name);
          setLoading(false);
          alert(response.data.message);
        } else if (response.data.status === 10) {
          setLoading(false);
          alert(response.data.message);
          navigate("/auth");
        } else {
          setLoading(false);
          alert(response.data.message);
        }
      } else {
        setLoading(false);
        alert("Error in Logging in , Please try again");
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error("Error signing up:", error.response.data);
    } finally {
      setLoading(false);
    }
  };
  const handleChangePassword = async (e) => {
    if(newPassword == "" || confirmPassword == "" || currentPassword == "" ){
      alert("some fields are still empty!")
    }

    if (newPassword != confirmPassword) {
      e.preventDefault();
      alert("your confirm password mismatches with new password")
    } else {
      setLoading(true);
      e.preventDefault();
      try {
        const response = await axios.post(
          CHANGE_PASSWORD,
          {
            currentPassword: currentPassword,
            newPassword:newPassword
          },
          {
            headers: {
              authorization: token, 
            },
          }
        );
        if (response.status === 200) {
          if (response.data.status === 1) {
            alert(response.data.message);
           
          } else if (response.data.status === 10) {
            setLoading(false);
            alert(response.data.message);
            navigate("/auth");
          } else {
            setLoading(false);
            alert(response.data.message);
          }
        } else {
          setLoading(false);
          alert("Error in Logging in , Please try again");
        }
        // Handle the response as needed
      } catch (error) {
        // Handle errors here
        console.error("Error signing up:", error.response.data);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!token || token.length === 0) {
      navigate("/");
    }
  });
  return (
    <div>
      <Header />
      <div
        className="contentContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "84px",
        }}
      >
        <div
          className="process_images_container"
          style={{
            backgroundImage: `url('./././backgroundone.png')`,
            height: "300px",
            backgroundSize: "cover",
          }}
        >
          {/* <img className="process_images_inner_first"  style={{ height:'250px'}} alt="logo" src="./././g10.png" /> */}

          {/* <div className="banner_images"> */}
          <div className="bannerdescription">
            <h3 className="banner_titles_small">My Profile</h3>
            <h1 className="banner_titles_large">Connecting You with </h1>
            <h1 className="banner_titles_large"> Trusted Contractors !</h1>
            <p className="banner_text">Simplifying Contractor Searches.</p>
          </div>
          {/* <img className="process_images_inner"  style={{ height:'300px' }} alt="logo" src="./././1122.png" /> */}
          {/* </div> */}
        </div>
        <div
          className="addRequestFormConatiner"
          style={{ width: "70%", marginTop: "-34px", marginBottom: "30px" }}
        >
          <div className="tabs">
            <div
              onClick={() => handleTabClick("edit")}
              className={`left_tab ${
                selectedTab === "edit" ? "selected_tab" : ""
              }`}
            >
              My Profile
            </div>
            <div
              className={`right_tab ${
                selectedTab === "change_password" ? "selected_tab" : ""
              }`}
              onClick={() => handleTabClick("change_password")}
            >
              Change password
            </div>
          </div>
          {selectedTab === "edit" ? (
            <form onSubmit={handleUpdateProfile}>
              {/* <h3 className="searchTitle">Edit profile</h3> */}

              <input
                type="email"
                placeholder="Your Email"
                name="email"
                readOnly
                required
                value={email}
              />

              <input
                type="text"
                name="subject"
                placeholder="Subject"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              {/* <button type="submit" disabled={loading}> */}
              <button type="submit">
                {loading ? (
                  <Bars color="#ffffff" height={20} width={20} />
                ) : (
                  "Update"
                )}
              </button>
            </form>
          ) : (
            <form onSubmit={handleChangePassword}>
              {/* <h3 className="searchTitle">Edit profile</h3> */}

              <input
                type="password"
                placeholder="Enter your current password"
                name="current_password"
                required
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />

              <input
                type="password"
                placeholder="Enter your new password"
                name="new_password"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Confirm new password"
                name="confirm_password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {/* <button type="submit" disabled={loading}> */}
              <button type="submit">
                {loading ? (
                  <Bars color="#ffffff" height={20} width={20} />
                ) : (
                  "Update Password"
                )}
              </button>
            </form>
          )}
        </div>
        <div className="faq-section">
          <h2 style={{ textAlign: "center" }}>
            Unlock Contractor Insights: Your Questions, Our Answers
          </h2>
          <ul className="faq-section__content">
            <li>
              <div className="gridItem">
                <h3>
                  How can Contractor-Fax help me access contractor information
                  conveniently??
                </h3>
                <p>
                  Contractor-Fax provides a user-friendly interface with various
                  search options and refined parameters, making it easy to
                  access contractor information. Whether you need to search by
                  name, location, or specific criteria, our platform ensures
                  thorough results that cater to your specific needs.
                </p>
              </div>
            </li>
            <li>
              <div className="gridItem">
                <h3>
                  What kind of information can I expect from the detailed PDF
                  reports provided by Contractor-Fax?
                </h3>
                <p>
                  Our detailed PDF reports contain essential information such as
                  document numbers and personal details of contractors. These
                  reports are meticulously compiled to offer comprehensive
                  insights into contractor backgrounds, ensuring that you have
                  all the information you need for your projects or business
                  dealings.
                </p>
              </div>
            </li>
            <li>
              <div className="gridItem">
                <h3>
                  How secure are payments made on the Contractor-Fax website?
                </h3>
                <p>
                  At Contractor-Fax, we prioritize the security of our users'
                  payments. We utilize industry-standard encryption and security
                  measures to safeguard your payment information. When you make
                  a payment on our website to access detailed reports, you can
                  rest assured that your transaction is secure and your
                  sensitive data is protected.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Profile;
