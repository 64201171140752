import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import {
  ADD_FAX_REQUESTS,
  ADD_TEMP_REQUESTS,
  STRIPEPAYMENT,
  UPLOAD_MEDIA_REQUEST,
} from "../config/api-config";
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const AddRequest = () => {
  const navigate = useNavigate();
  const [searchname, setSearchName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isEmailRead, setEmailIsRead] = useState(true);
  const [licensenumber, setLicenseNumber] = useState("");
  const [licenseFile, setLicenseFile] = useState("");
  const [licensetype, setLicenseType] = useState("");
  const [uploadedText, setUploadedText] = useState("Upload");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const type = localStorage.getItem("type");

  const token = localStorage.getItem("token");
  const currentEmail = localStorage.getItem("email");
  const handleFileSelection = async (e) => {
    e.preventDefault();
    document.getElementById("imageUploadInput").click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLicenseFile(file);
        // logMessage(formData);
        setUploadedText(file.name);
      };
      reader.readAsDataURL(file);
    }
  };
  const addRequestTest = async (req, res) => {
    try {
      const response = await axios.post(STRIPEPAYMENT);
      if (response.status === 200) {
        console.log(response.data);
      } else {
        setLoading(false);
        alert("Error in Logging in , Please try again");
      }
    } catch (error) {
      console.error("Error fetching requests:", error);
      // helper.errorResponse(res, "Something went wrong");
    }
  };
  const handleRequestCall = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      if (licenseFile == "") {
        handleAddRequest("");
      } else {
        const formData = new FormData();
        formData.append("file", licenseFile);

        console.log(token);
        // await new Promise(resolve => setTimeout(resolve, 3000));
        const response = await axios.post(UPLOAD_MEDIA_REQUEST, formData, {
          headers: {
            authorization: token,
          },
        });
        if (response.status === 200) {
          if (response.data.status === 1) {
            const uploadedUrl = response.data.data;
            handleAddRequest(uploadedUrl);
          } else if (response.data.status === 10) {
            setLoading(false);
            alert(response.data.message);
          } else {
            setLoading(false);
            alert(response.data.message);
          }
        } else {
          setLoading(false);
          alert("Error in Logging in , Please try again");
        }
      }
    } catch (error) {
      // Handle errors here
      console.error("Error signing up:", error.response.data);
    } finally {
      setLoading(false);
    }
  };
  const handleAddRequest = async (uploadedUrl) => {
    setLoading(true);
    try {
      // await new Promise(resolve => setTimeout(resolve, 3000));
      if (searchname == "" && licensenumber == "" && licensetype == "") {
        alert(
          "one of these values is needed Contractor name, License number, License type"
        );
      } else {
        const response = await axios.post(
          ADD_TEMP_REQUESTS,
          {
            email: userEmail,
            contractor_name: searchname,
            lic_number: licensenumber,
            lic_type: licensetype,
            city: city,
            licenseFile: licenseFile,
            uploadedText:uploadedText,
            uploadedUrl:uploadedUrl
          },
          {
            headers: {
              authorization: token,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.status === 1) {
            alert(response.data.message);
            navigate("/payment-confirm?request_number="+response.data.data.request_number);
          } else if (response.data.status === 10) {
            setLoading(false);
            alert(response.data.message);
            navigate("/auth");
          } else {
            setLoading(false);
            alert(response.data.message);
          }
        } else {
          setLoading(false);
          alert("Error in Logging in , Please try again");
        }
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error("Error signing up:", error.response.data);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(currentEmail)
    if (!token || token.length === 0) {
      // navigate("/");
      setEmailIsRead(false);
    } else {
      setUserEmail(currentEmail);
    }
  });
  return (
    <div>
      <Header />
      <div
        className="contentContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "84px",
        }}
      >
        <div className="process_images_container">
          <img
            className="process_images_inner_first"
            style={{ height: "250px" }}
            alt="logo"
            src="./././g10.png"
          />

          {/* <div className="banner_images"> */}
          <div>
            <h3 className="banner_titles_small">Get Contractors</h3>
            <h1 className="banner_titles_large">Ease your finding</h1>
            <h1 className="banner_titles_large"> process on CONTRACTOR Fax.</h1>
            <p className="banner_text">Get details in quick time</p>
          </div>
          <img
            className="process_images_inner"
            style={{ height: "300px" }}
            alt="logo"
            src="./././1122.png"
          />
          {/* </div> */}
        </div>
        <div
          className="addRequestFormConatiner"
          style={{ width: "70%", marginTop: "-34px", marginBottom: "30px" }}
        >
          <form onSubmit={handleRequestCall}>
            <h3 className="searchTitle">Search Contractors</h3>
            {!isEmailRead && (
            <input
              type="text"
              readOnly={isEmailRead}
              placeholder="Please enter your email"
              name="useremail"
              required
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          )}
            <input
              type="text"
              placeholder="Contractor/Business Name"
              name="searchname"
              // required
              value={searchname}
              onChange={(e) => setSearchName(e.target.value)}
            />

            <input
              type="text"
              name="licensenumber"
              placeholder="License Number"
              // required
              value={licensenumber}
              onChange={(e) => setLicenseNumber(e.target.value)}
            />
            <input
              type="text"
              name="city"
              placeholder="City or Country"
              // required
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <input
              type="text"
              name="licensetype"
              placeholder="License Type"
              // required
              value={licensetype}
              onChange={(e) => setLicenseType(e.target.value)}
            />
            <div className="fileUpload" style={{ marginTop: "8px" }}>
              <img src="./././upload.png"></img>
              <p>{uploadedText}</p>
              <button onClick={handleFileSelection}>Choose File</button>
            </div>
            <input
              type="file"
              id="imageUploadInput"
              name="licensefile"
              placeholder="License File"
              // required
              hidden
              onChange={handleFileChange}
            />

            <button type="submit" disabled={loading}>
              {loading ? (
                <Bars color="#ffffff" height={20} width={20} />
              ) : (
                "Submit Request"
              )}
            </button>
          </form>
        </div>
        <div className="faq-section">
          <h2 style={{ textAlign: "center" }}>
            Unlock Contractor Insights: Your Questions, Our Answers
          </h2>
          <ul className="faq-section__content">
            <li>
              <div className="gridItem">
                <h3>
                  How can Contractor-Fax help me access contractor information
                  conveniently??
                </h3>
                <p>
                  Contractor-Fax provides a user-friendly interface with various
                  search options and refined parameters, making it easy to
                  access contractor information. Whether you need to search by
                  name, location, or specific criteria, our platform ensures
                  thorough results that cater to your specific needs.
                </p>
              </div>
            </li>
            <li>
              <div className="gridItem">
                <h3>
                  What kind of information can I expect from the detailed PDF
                  reports provided by Contractor-Fax?
                </h3>
                <p>
                  Our detailed PDF reports contain essential information such as
                  document numbers and personal details of contractors. These
                  reports are meticulously compiled to offer comprehensive
                  insights into contractor backgrounds, ensuring that you have
                  all the information you need for your projects or business
                  dealings.
                </p>
              </div>
            </li>
            <li>
              <div className="gridItem">
                <h3>
                  How secure are payments made on the Contractor-Fax website?
                </h3>
                <p>
                  At Contractor-Fax, we prioritize the security of our users'
                  payments. We utilize industry-standard encryption and security
                  measures to safeguard your payment information. When you make
                  a payment on our website to access detailed reports, you can
                  rest assured that your transaction is secure and your
                  sensitive data is protected.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AddRequest;
