import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import {
  ADD_FAX_REQUESTS,
  ADD_TEMP_REQUESTS,
  GET_CUSTOMER_DATA,
  GET_CUSTOMER_DATA_REQUEST,
  PAY_NOW,
  STRIPEPAYMENT,
  UPLOAD_MEDIA_REQUEST,
} from "../config/api-config";
import { Bars } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
const ConfirmPayment = () => {
  const navigate = useNavigate();
  const [searchname, setSearchName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [licenseFile, setLicenseFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const token = localStorage.getItem("token");
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const request_number = queryParameters.get("request_number");

  const paynow = async (e) => {
    if(customerId == ""){
      e.preventDefault();
      alert("oops invalid request id!")
    }else{
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post(PAY_NOW, {
        requestNumber: request_number,
        customerId: customerId,
      });
      if (response.status === 200) {
        if (response.data.status === 1) {
          const paymentUrl = response.data.data.url;
          window.location.href = paymentUrl;
          // navigate(paymentUrl);
        } else if (response.data.status === 10) {
          setLoading(false);
          alert(response.data.message);
          navigate("/auth");
        } else {
          setLoading(false);
          alert(response.data.message);
        }
      } else {
        setLoading(false);
        alert("Error in Logging in , Please try again");
      }

      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error("Error signing up:", error.response.data);
    } finally {
      setLoading(false);
    }
    }
  };
  const getCustomerDetails = async () => {
    console.log("ggg")
    try {
      console.log("ghdhfhgg")
      const response = await axios.post(GET_CUSTOMER_DATA_REQUEST, {
        requestNumber: request_number,
      });
    
      if (response.status === 200) {
      
        if (response.data.status === 1) {
         
          setCustomerId(response.data.data);
          // console.log(customerId)
        } else if (response.data.status === 10) {
       
          setLoading(false);
          alert(response.data.message);
          navigate("/auth");
        } else {
          setLoading(false);
          navigate("/");
          alert(response.data.message);
        }
      } else {
        console.log("dssh")
        setLoading(false);
        alert("Error in Logging in , Please try again");
      }

      // Handle the response as needed
    } catch (error) {
      console.log("dssh")
      // Handle errors here
      console.error("Error signing up:", error.response.data);
    } finally {
      console.log("dsdsdfsh")
      setLoading(false);
    }
  };
  useEffect(() => {
    getCustomerDetails(); // Fetch customer details when component mounts
  }, []);
  return (
    <div>
      <Header />
      <div
        className="contentContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "84px",
        }}
      >
        <div className="process_images_container">
          <img
            className="process_images_inner_first"
            style={{ height: "250px" }}
            alt="logo"
            src="./././cardicon.png"
          />

          {/* <div className="banner_images"> */}
          <div>
            <h3 className="banner_titles_small">Secure Payments</h3>
            <h1 className="banner_titles_large">
              Fast, Easy and Safe Transactions
            </h1>
            <h1 className="banner_titles_large">Your Purchse, Our Priority</h1>
            <p className="banner_text">Instant Confirmation</p>
          </div>
          <img
            className="process_images_inner"
            style={{ height: "300px" }}
            alt="logo"
            src="./././1122.png"
          />
          {/* </div> */}
        </div>
        <div
          className="addRequestFormConatiner"
          style={{ width: "70%", marginTop: "-34px", marginBottom: "30px" }}
        >
          <form onSubmit={paynow}>
            <h2 className="searchTitle confirmsearch">#{request_number}</h2>
            <span>You are just one step to submit your request</span>
            <h3>Payment Details</h3>
            <img style={{ width: "300px" }} src="./././cards.png"></img>
            <div className="inrow row_one">
              <h3>Subtotal</h3>
              <h4>$10</h4>
            </div>
            <div className="inrow row_one">
              <h3>Tax</h3>
              <h4>$0</h4>
            </div>
            <div className="inrow row_one">
              <h3>Total</h3>
              <h4>$10</h4>
            </div>
            <button type="submit" disabled={loading}>
              {loading ? (
                <Bars color="#ffffff" height={20} width={20} />
              ) : (
                "Pay Now"
              )}
            </button>
          </form>
        </div>
        <div className="faq-section">
          <h2 style={{ textAlign: "center" }}>
            Unlock Contractor Insights: Your Questions, Our Answers
          </h2>
          <ul className="faq-section__content">
            <li>
              <div className="gridItem">
                <h3>
                  How can Contractor-Fax help me access contractor information
                  conveniently??
                </h3>
                <p>
                  Contractor-Fax provides a user-friendly interface with various
                  search options and refined parameters, making it easy to
                  access contractor information. Whether you need to search by
                  name, location, or specific criteria, our platform ensures
                  thorough results that cater to your specific needs.
                </p>
              </div>
            </li>
            <li>
              <div className="gridItem">
                <h3>
                  What kind of information can I expect from the detailed PDF
                  reports provided by Contractor-Fax?
                </h3>
                <p>
                  Our detailed PDF reports contain essential information such as
                  document numbers and personal details of contractors. These
                  reports are meticulously compiled to offer comprehensive
                  insights into contractor backgrounds, ensuring that you have
                  all the information you need for your projects or business
                  dealings.
                </p>
              </div>
            </li>
            <li>
              <div className="gridItem">
                <h3>
                  How secure are payments made on the Contractor-Fax website?
                </h3>
                <p>
                  At Contractor-Fax, we prioritize the security of our users'
                  payments. We utilize industry-standard encryption and security
                  measures to safeguard your payment information. When you make
                  a payment on our website to access detailed reports, you can
                  rest assured that your transaction is secure and your
                  sensitive data is protected.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPayment;
