import React from "react";
import { Link } from "react-router-dom";

function Footermain() {
  const type = localStorage.getItem("type");
  console.log(type);
  return (
    // <div>
    <footer className="footer">
      <div className="footer-section">
        <Link
          to="/privacypolicy"
          style={{
            color: "#ffffff",
            marginRight: "30px",
            textDecoration: "none",
          }}
        >
          Privacy Policy
        </Link>
      </div>
      <div className="footer-section">
        <Link
          to="/terms-condition"
          style={{
            color: "#ffffff",
            marginRight: "30px",
            textDecoration: "none",
          }}
        >
          Terms & Condition
        </Link>
      </div>
      <div className="footer-section">
        <Link
          to="/about-us"
          style={{
            color: "#ffffff",
            marginRight: "30px",
            textDecoration: "none",
          }}
        >
          About Us
        </Link>
      </div>
      {/* {type == "admin" ? (
        <div className="footer-section">
          <Link
            to="/homeadmin"
            style={{
              color: "#ffffff",
              marginRight: "30px",
              textDecoration: "none",
            }}
          >
            Home
          </Link>
        </div>
      ) : (
        <div className="footer-section">
          <Link
            to="/"
            style={{
              color: "#ffffff",
              marginRight: "30px",
              textDecoration: "none",
            }}
          >
            Home
          </Link>
        </div>
      )} */}
    </footer>
    // </div>
  );
}

export default Footermain;
