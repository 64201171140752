import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import {
  CHECK_REQUEST_EXISTS,
  MAIN_SAVE_REQUEST,

} from "../config/api-config";
import { Bars } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
const SuccessPayment = () => {
  const navigate = useNavigate();
  const [searchname, setSearchName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [licenseFile, setLicenseFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const token = localStorage.getItem("token");
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const session_id = queryParameters.get("request_number");
  const checkout_session_id = queryParameters.get("session_id");

  const paynow = async (e) => {
    //   setLoading(true);
    //   e.preventDefault();
    //   try {
    //     const response = await axios.post(PAY_NOW, {
    //       requestNumber: request_number,
    //       customerId: customerId,
    //     });
    //     if (response.status === 200) {
    //       if (response.data.status === 1) {
    //         const paymentUrl = response.data.data.url;
    //         window.location.href = paymentUrl;
    //         // navigate(paymentUrl);
    //       } else if (response.data.status === 10) {
    //         setLoading(false);
    //         alert(response.data.message);
    //         navigate("/auth");
    //       } else {
    //         setLoading(false);
    //         alert(response.data.message);
    //       }
    //     } else {
    //       setLoading(false);
    //       alert("Error in Logging in , Please try again");
    //     }
    //     // Handle the response as needed
    //   } catch (error) {
    //     // Handle errors here
    //     console.error("Error signing up:", error.response.data);
    //   } finally {
    //     setLoading(false);
    //   }
  };
  const fetchData = async () => {
    setLoading(true);
    //  await new Promise(resolve => setTimeout(resolve, 3000));
    try {
      const response = await axios.post(
        MAIN_SAVE_REQUEST,
        {
          requestNumber: session_id,
          sessionId:checkout_session_id
        },
        {
          headers: {
            authorization: token,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.status === 1) {
          console.log("maincase")
        } else if (response.data.status === 10) {
          setLoading(false);
          alert(response.data.message);
          navigate("/");
        } else {
          setLoading(false);
          console.log("elsecase")
          navigate("/");
          console.log(response.data.message);
          // alert(response.data.message)
        }
      } else {
        navigate("/");
        setLoading(false);
        alert("Error in Logging in , Please try again");
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error("Error signing up:", error.response.data);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (session_id) {
      fetchData();
    } else {
      navigate("/");
    }
  }, [session_id]);
  return (
    <div>
      <Header />
      <div
        className="contentContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "84px",
        }}
      >
        <div className="process_images_container">
          <img
            className="process_images_inner_first"
            style={{ height: "250px" }}
            alt="logo"
            src="./././cardicon.png"
          />

          {/* <div className="banner_images"> */}
          <div>
            <h3 className="banner_titles_small">Secure Payments</h3>
            <h1 className="banner_titles_large">
              Fast, Easy and Safe Transactions
            </h1>
            <h1 className="banner_titles_large">Your Purchse, Our Priority</h1>
            <p className="banner_text">Instant Confirmation</p>
          </div>
          <img
            className="process_images_inner"
            style={{ height: "300px" }}
            alt="logo"
            src="./././1122.png"
          />
          {/* </div> */}
        </div>
        <div
          className="addRequestFormConatiner"
          style={{ width: "70%", marginTop: "-34px", marginBottom: "30px" }}
        >
          {/* {session_id ? ( */}
          <form onSubmit={paynow}>
            <h2 className="searchTitle confirmsearch">#{session_id}</h2>
            <span>Thank you for placing request</span>
            <img style={{ width: "300px" }} src="./././success.png"></img>
            <h2>Request Added Successfully</h2>
            <span>Your requested information will be shared on your email or you can login into your account</span>
            {/* <div className="inrow row_one">
              <h3>Subtotal</h3>
              <h4>$10</h4>
            </div>
            <div className="inrow row_one">
              <h3>Tax</h3>
              <h4>$0</h4>
            </div>
            <div className="inrow row_one">
              <h3>Total</h3>
              <h4>$10</h4>
            </div> */}
            {/* <button type="submit" disabled={loading}>
              {loading ? (
                <Bars color="#ffffff" height={20} width={20} />
              ) : (
                "Pay Now"
              )}
            </button> */}
          </form>
        </div>
        <div className="faq-section">
          <h2 style={{ textAlign: "center" }}>
            Unlock Contractor Insights: Your Questions, Our Answers
          </h2>
          <ul className="faq-section__content">
            <li>
              <div className="gridItem">
                <h3>
                  How can Contractor-Fax help me access contractor information
                  conveniently??
                </h3>
                <p>
                  Contractor-Fax provides a user-friendly interface with various
                  search options and refined parameters, making it easy to
                  access contractor information. Whether you need to search by
                  name, location, or specific criteria, our platform ensures
                  thorough results that cater to your specific needs.
                </p>
              </div>
            </li>
            <li>
              <div className="gridItem">
                <h3>
                  What kind of information can I expect from the detailed PDF
                  reports provided by Contractor-Fax?
                </h3>
                <p>
                  Our detailed PDF reports contain essential information such as
                  document numbers and personal details of contractors. These
                  reports are meticulously compiled to offer comprehensive
                  insights into contractor backgrounds, ensuring that you have
                  all the information you need for your projects or business
                  dealings.
                </p>
              </div>
            </li>
            <li>
              <div className="gridItem">
                <h3>
                  How secure are payments made on the Contractor-Fax website?
                </h3>
                <p>
                  At Contractor-Fax, we prioritize the security of our users'
                  payments. We utilize industry-standard encryption and security
                  measures to safeguard your payment information. When you make
                  a payment on our website to access detailed reports, you can
                  rest assured that your transaction is secure and your
                  sensitive data is protected.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SuccessPayment;
