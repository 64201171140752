import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SEND_OTP }  from '../config/api-config'
import {Bars} from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";

const SendOtp = () => {
  const navigate = useNavigate();
  const [email, setSearchName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddRequest = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // await new Promise(resolve => setTimeout(resolve, 3000));

      const response = await axios.post(SEND_OTP, {
        'email':email,
        'isLive':'1',
      },
      );

      if(response.status === 200){
        if(response.data.status === 1){
          await localStorage.setItem('forgot_email', email);
          alert(response.data.message);
          navigate("/verify_otp");
        }else if(response.data.status === 10){
          setLoading(false);
          alert(response.data.message)
        //   navigate("/");
        }else{
          setLoading(false);
          alert(response.data.message)
        }
      }else{
        setLoading(false);
        alert("Error in Logging in , Please try again")
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error('Error signing up:', error.response.data);
    }finally{
      setLoading(false);
    }
  };

  return (
    <div style={{overflow:'hidden'}}>
    <div className='contentContainer openscreen'style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    {/* <img className="auth_images" alt="logo" src="./././reset.png" /> */}
    <div className="addRequestFormConatiner forgotscreen" style={{ width: '100%',justifyContent: 'center', marginTop: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <img className="auth_images" alt="logo" src="./././reset.png" />
      <form style={{width:'50%'}} onSubmit={handleAddRequest}>
        <h3>Forgot Password</h3>
        <input  
          type="email"
          placeholder="Please enter your email"
          name="email"
          required
          value={email}
          onChange={(e) => setSearchName(e.target.value)}
        />
         
        <button type="submit" disabled={loading}>
          {loading ? (
            <Bars  color="#ffffff" height={20} width={20} />
          ) : (
            'Send Otp'
          )}
        </button>
      </form>
    </div>
    </div>
    </div>
  );
}

export default SendOtp;
