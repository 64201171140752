import React from 'react';

const TermsCondition = () => {
    return (
        <div className='contentContainer'style={{ padding:'35px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
 <h1>Terms and Conditions</h1>
    <h3>1. Introduction</h3>
    <p>Welcome to Our Website. By accessing our website, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Our relationship with you in relation to this website.</p>

    <h3>2. Use of the Website</h3>
    <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>

    <h3>3. User Account</h3>
    <p>If you create an account on the website, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.</p>

    <h3>4. Intellectual Property</h3>
    <p>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</p>

    <h3>5. Governing Law</h3>
    <p>These terms and conditions are governed by and construed in accordance with the laws of [Your Country] and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

    

        </div>
    );
}

export default TermsCondition;
