// Home.js
import React , {useEffect, useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'
const Header = () => {
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("name");
  const type = localStorage.getItem("type");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isMobileDevice = /mobile|android|iphone|ipad|tablet/i.test(userAgent);
      setIsMobile(isMobileDevice || window.innerWidth <= 768);
    };

    checkIfMobile();

    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  useEffect(() => {
    if(!token || token.length === 0){
      // navigate("/");
    }
  }, []);

    const navigate = useNavigate();
  const handleSignOut = async () => {
    // Add sign-out logic here
    await localStorage.removeItem('token');
    await localStorage.removeItem('email');
    await localStorage.removeItem('type');
    await localStorage.removeItem('name');
    await navigate("/auth");
    // window.location.href = "/auth";
  };
 

  return (
    <div>
       {!isMobile && (
    <header className="desktopheader" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '2px 10px 2px 2px', position: 'fixed', top: 0, zIndex: 1, width: '100%',height: '64px' }}>
      <div style={{width:'20%'}}>
      <h2 style={{ flex: 1, textAlign: 'left', color: '#ffffff', marginLeft: '10px' }}>
        <img style={{ width: '160px', marginRight: '12px' }} alt="logo" src="./././weblogo.png" />
      </h2>
      </div>
      {type == 'user' || type == undefined ? (
        <>
      <div style={{ flex: 2 }}>
      <Link to="/" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Home</Link>
      <Link to="/about-us" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>About</Link>
      <Link to="/contactus" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Contact</Link>

      </div>
      </>
      ): type === 'admin' ? (
        <>
      <div style={{ flex: 2 }}>
      <Link to="/homeadmin" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Home</Link>
    
      </div>          </>
      ) : null}
      {token && token.length > 0 ? (
      <>
      {type && type === "user" ? (
      <>
        <Link to="/addrequest" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Add Request</Link>
      </>
    ) : null}
        <Link to="/profile" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Profile</Link>
        <FontAwesomeIcon style={{ marginRight: '5px', color: '#ffffff', cursor: 'pointer' }} onClick={handleSignOut} icon={faSignOut} />
      </>
    ) : (
      <>
        <Link to="/auth" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Login | Signup</Link>
      </>
    )}
    </header>
 )}
  {isMobile && (
    <header className="mobileheader" style={{ display: 'flex',flexDirection:"column", justifyContent: 'end', alignItems: 'center', padding: '5px 10px 2px 2px', position: 'fixed', top: 0, zIndex: 1, width: '100%' }}>
      <div>
      {/* <h2 style={{ flex: 1, textAlign: 'left', color: '#ffffff', marginLeft: '10px' }}> */}
        <img style={{ width: '160px', marginRight: '12px' }} alt="logo" src="./././weblogo.png" />
      {/* </h2> */}
      </div>
      <div style={{display:"flex", alignItems:"center"}}>
      {type == 'user' || type == undefined ? (
        <>
      <div style={{ flex: 2 }}>
      <Link to="/" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Home</Link>
      <Link to="/about-us" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>About</Link>
      <Link to="/contactus" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Contact</Link>

      </div>
      </>
      ): type === 'admin' ? (
        <>
      <div style={{ flex: 2 }}>
      <Link to="/homeadmin" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Home</Link>
    
      </div>          </>
      ) : null}
      {token && token.length > 0 ? (
      <>
      {type && type === "user" ? (
      <>
        <Link to="/addrequest" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Add Request</Link>
      </>
    ) : null}
        <Link to="/profile" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Profile</Link>
        <FontAwesomeIcon style={{ marginRight: '5px', color: '#ffffff', cursor: 'pointer' }} onClick={handleSignOut} icon={faSignOut} />
      </>
    ) : (
      <>
        <Link to="/auth" style={{ color: '#ffffff', marginRight: '30px', textDecoration: 'none' }}>Login | Signup</Link>
      </>
    )}
    </div>
    </header>
    )}
  </div>
  
  );
};
export default Header;