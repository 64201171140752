
import React, { useState } from "react";
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import {UPLOAD_FILE_DATA } from "../config/api-config";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import { htmlToText } from 'html-to-text';

const TextEditor = ({ editRequestsAdmin, statusSearch }) => {
  const currentUrl = window.location.href;
  const token = localStorage.getItem("token");
  const requestIdParam = new URLSearchParams(currentUrl.split('?')[1]).get('request_id');
  const [collectedData, setCollectedData] = useState("");
  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      ["link"]
    ]
  };  

  const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  const handleProcedureContentChange = (content) => {
    setCollectedData(content);
  };

  const generateFile = () => {
    const plainText = htmlToText(collectedData, {
      wordwrap: 130,
      preserveNewlines: true,
      uppercaseHeadings: false,
      singleNewLineParagraphs: true
    });
    const file = new Blob([plainText], { type: 'text/plain' });
    return new File([file], 'document.txt', { type: 'text/plain' });
  };

  const sendEditorBoxData = async (e) => {
   
    e.preventDefault();
    if(collectedData.length > 0){
      setLoading(true);
    const file = generateFile();
    const formData = new FormData();
    formData.append('file', file);

    try {
      if(statusSearch){
        const response = await axios.post(UPLOAD_FILE_DATA, formData, {
          headers: {
            'authorization': token,
            'request_id': requestIdParam,
            'status':statusSearch,
          },
        });
  
        if (response.status === 200) {
          if (response.data.status === 1) {
            editRequestsAdmin();
            window.location.reload();
            alert(response.data.message);
          } else {
            // alert(response.data.message);
          }
      }
      } else {
        alert("Status required");
      }
    } catch (error) {
      console.error('Error uploading file:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  }else{
    alert("empty data");
  }
  };

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <Bars color="#1976d2" loading={loading} height="40" width="40" />
        </div>
      )}
      <h1 style={{ textAlign: "center", fontSize: '1.3em', marginBottom: '5px', marginTop: '15px' }}>
        Create Document
      </h1>
      <button onClick={sendEditorBoxData} style={{ cursor: 'pointer', marginRight:'10px', 'float':'right'}} className="quilbutton">
          Upload
        </button>
      <div className="text-editor">
        
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          placeholder="Write your content ...."
          onChange={handleProcedureContentChange}
          style={{ height: "300px", width: '100%', marginBottom:'10px' }}
        />
      </div>
   
      {fileUrl && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <a href={fileUrl} target="_blank">
            <img src="./././fileicon.png" alt="File Icon" />
          </a>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
