// client/src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { LOGIN_API_URL }  from '../config/api-config'
import {ThreeDots} from 'react-loader-spinner';

const Login = ({ handleOnClick }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin =  async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(LOGIN_API_URL, {
        email,
        password
      });
      if(response.status === 200){
        if(response.data.status === 1){
          const token = response.data.data.token;
          const type = response.data.data.type;
          const name = response.data.data.name;
          const email = response.data.data.email;
          await localStorage.setItem('token', token);
          await localStorage.setItem('email', email);
          await localStorage.setItem('type', type);
          await localStorage.setItem('name', name);
          if(type == "admin"){
            navigate("/homeadmin");
            // window.location.href = "/homeadmin";

          }else{
            navigate("/");
         
            // window.location.href = "/";

          }
         

        }else{
          setLoading(false);
          alert(response.data.message)
        }
      }else{
        setLoading(false);
        alert("Error in Logging in , Please try again")
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error('Error signing up:', error.response.data);
    }
  };
  // const [currentComponent, setCurrentComponent] = useState('login');
  // const [buttonText, setButtonText] = useState('Sign Up');

  // const handleOnClick = (componentType) => {
  //   setButtonText(componentType === 'login' ? 'Sign Up' : 'Sign In');
  //   setCurrentComponent(componentType);
  // };

  return (
    <div className="form-container sign-in-container">
      <form onSubmit={handleLogin}>
      <img className="auth_images" alt="logo" src="./././login.png" />
        <p className='title_class'>Sign in</p>
       
        {/* <div className="social-container">
          <a href="#" className="social">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="#" className="social">
            <i className="fab fa-google-plus-g" />
          </a>
          <a href="#" className="social">
            <i className="fab fa-linkedin-in" />
          </a>
        </div> */}
        {/* <span>use your account</span> */}
        <input
          type="email"
          placeholder="Email"
          name="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
       
        <input
          type="password"
          name="password"
          placeholder="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
          
          <Link to="/forgot_password"><span className="auth_texts" href="#">Forgot your password?</span></Link> 
        <span className="auth_texts"
          id="signUp"
          onClick={() => handleOnClick('signup')}
        >
         Don't have an account yet?  Sign Up
        </span>
        <button type="submit" disabled={loading}>
          {loading ? (
            <ThreeDots  color="#000000" height={30} width={30} />
          ) : (
            'Sign In'
          )}
        </button>
      </form>
    </div>
  );
}

export default Login;
