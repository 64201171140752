import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RESET_PASSWORD }  from '../config/api-config'
import {Bars} from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const forgot_email = localStorage.getItem("forgot_email");

  const handleAddRequest = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // await new Promise(resolve => setTimeout(resolve, 3000));
        if(password !== confirmpassword){
            alert("password mismatch");
        }else{
            const response = await axios.post(RESET_PASSWORD, {
                'password':password,
                'email':forgot_email,
              });
              if(response.status === 200){
                if(response.data.status === 1){
                  alert(response.data.message);
                  navigate("/auth");
                }else if(response.data.status === 10){
                  setLoading(false);
                  alert(response.data.message)
                  navigate("/");
                }else{
                  setLoading(false);
                  alert(response.data.message)
                }
              }else{
                setLoading(false);
                alert("Error in Logging in , Please try again")
              }
        }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error('Error signing up:', error.response.data);
    }finally{
      setLoading(false);
    }
  };

  return (
    <div>
    <div className='contentContainer'style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    {/* <img className="auth_images" alt="logo" src="./././reset.png" /> */}
    <div className="addRequestFormConatiner forgotscreen" style={{ width: '100%',justifyContent: 'center', marginTop: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <img className="auth_images" alt="logo" src="./././change.png" />
      <form style={{width:'50%'}} onSubmit={handleAddRequest}>
        <h3>Reset Password</h3>
        <input
          type="password"
          name="password"
          placeholder="New Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

           <input
          type="password"
          name="password"
          placeholder="Confirm Password"
          required
          value={confirmpassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
         
        <button type="submit" disabled={loading}>
          {loading ? (
            <Bars  color="#ffffff" height={20} width={20} />
          ) : (
            'Reset Password'
          )}
        </button>
      </form>
    </div>
    </div>
    </div>
  );
}

export default VerifyOtp;
