import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import {
  GET_FAX_REQUESTS_DETAIL,
  UPLOAD_FILE_DATA,
  EDIT_FAX_REQUESTS,
} from "../config/api-config";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import { Bars } from "react-loader-spinner";
import "quill/dist/quill.snow.css";
import TextEditor from "./TextEditors";

const RequestDetail = () => {
  const [uploadedFile, setFile] = useState(null);
  const [statusSearch, statusSet] = useState("");
  const [fileName, setFileName] = useState(null);
  const [mediaData, setMediaData] = useState([]);
  const [sunbiz, setSunbiz] = useState("");
  const [lic_info, setLicInfo] = useState("");
  const [bbbRating, setBbbRating] = useState("");
  const [ownerInfo, setOwnerInfo] = useState("");
  const [googleReview, setGoogleReview] = useState("");
  const [socialAccount, setSocialAccount] = useState("");
  const [insurance, setInsurance] = useState("");
  // const fileTypes = ["JPG", "PNG", "GIF"];
  const placeholder = "-----";
  // const searchParams = new URLSearchParams(window.location.search);
  // const requestIdParam = searchParams.get('request_id');
  const currentUrl = window.location.href;
  const requestIdParam = new URLSearchParams(currentUrl.split("?")[1]).get(
    "request_id"
  );

  // console.log(requestId);
  const [singleEntry, setTableData] = useState({});

  // useEffect(() => {
  //   if( !token || token.length == 0){
  //     // if(type == "admin"){
  //     //   navigate("/homeadmin");
  //     // }else{
  //     //   navigate("/home");
  //     // }
  //     navigate("/");
  //   }
  // }, []);

  const statusChange = (event) => {
    console.log(event.target.value);
    if (event.target.value !== null || event.target.value !== "") {
      // console.log(event.target.value);
      statusSet(event.target.value);
    } else {
      statusSet(singleEntry.search_status);
    }
    // console.log(uploadedFile.name)
  };

  // Function to extract file name from URL
  function getFileNameFromUrl(url) {
    const urlParts = url.split("/");
    const fileName = urlParts[urlParts.length - 1].split("?")[0];
    return fileName;
  }

  const handleChange = (event) => {
    // console.log(event.target.files[0])
    const uploadedFile = event.target.files[0];
    // console.log(uploadedFile.name);
    setFileName(uploadedFile.name);
    setFile(uploadedFile);
    // console.log(uploadedFile.name)
  };
  const editRequestsAdmin = async (e) => {
    try {
      // console.log(token);
      // await new Promise(resolve => setTimeout(resolve, 3000));
      const response = await axios.post(
        EDIT_FAX_REQUESTS,
        {
          request_id: requestIdParam,
          status: statusSearch,
          sunbiz: sunbiz,
          licensure: lic_info,
          bbb: bbbRating,
          insurance: insurance,
          owner_info: ownerInfo,
          google_reviews: googleReview,
          social_account: socialAccount,
        },
        {
          headers: {
            authorization: token,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.status === 1) {
          alert(response.data.message);
          window.location.reload(); // Reload the site
        } else if (response.data.status === 10) {
          setLoading(false);
          alert(response.data.message);
          //   navigate("/");
        } else {
          setLoading(false);
          alert(response.data.message);
        }
      } else {
        setLoading(false);
        alert("Error in Logging in , Please try again");
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error("Error signing up:", error.response.data);
    } finally {
      setLoading(false);
    }
  };
  const uploadFile = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", uploadedFile);

    try {
      console.log(token);
      // await new Promise(resolve => setTimeout(resolve, 3000));
      const response = await axios.post(UPLOAD_FILE_DATA, formData, {
        headers: {
          authorization: token,
          request_id: requestIdParam,
        },
      });
      if (response.status === 200) {
        if (response.data.status === 1) {
          editRequestsAdmin();
          // alert(response.data.message);
          // window.location.reload(); // Reload the site
        } else if (response.data.status === 10) {
          setLoading(false);
          alert(response.data.message);
          //   navigate("/");
        } else {
          setLoading(false);
          alert(response.data.message);
        }
      } else {
        setLoading(false);
        alert("Error in Logging in , Please try again");
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error("Error signing up:", error.response.data);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  //   const singleEntry = { id: 1, name: 'John Doe', age: 30, city: 'New York' };

  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token || token.length === 0) {
      navigate("/");
    } else {
      // console.log(requestIdParam);
      const fetchData = async () => {
        setLoading(true);
        //  await new Promise(resolve => setTimeout(resolve, 3000));
        try {
          const response = await axios.post(
            GET_FAX_REQUESTS_DETAIL,
            {
              request_id: requestIdParam,
            },
            {
              headers: {
                authorization: token, // Adjust content type as needed
              },
            }
          );
          if (response.status === 200) {
            if (response.data.status === 1) {
              setTableData(response.data.data);
              statusSet(response.data.data.search_status);
              setBbbRating(response.data.data.bbb_rating);
              setSocialAccount(response.data.data.social_media_account);
              setSunbiz(response.data.data.sunbiz_registration);
              setGoogleReview(response.data.data.google_reviews);
              setOwnerInfo(response.data.data.owner_information);
              setInsurance(response.data.data.insurance_information);
              setLicInfo(response.data.data.licensure_info);
              setMediaData(response.data.data.mediaData);
            } else if (response.data.status === 10) {
              setLoading(false);
              alert(response.data.message);
              navigate("/");
            } else {
              setLoading(false);
              console.log(response.data.message);
              // alert(response.data.message)
            }
          } else {
            setLoading(false);
            alert("Error in Logging in , Please try again");
          }
          // Handle the response as needed
        } catch (error) {
          // Handle errors here
          console.error("Error signing up:", error.response.data);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  return (
    <div>
      <Header />
      {loading && (
        <div className="loader-container">
          <Bars color="#1976d2" loading={loading} height="40" width="40" />
        </div>
      )}
      <div
        className="contentContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "84px",
        }}
      >
        <div
          className="process_images_container"
          style={{
            backgroundImage: `url('./././backgroundone.png')`,
            height: "300px",
            backgroundSize: "cover",
          }}
        >
          {/* <img className="process_images_inner_first"  style={{ height:'250px'}} alt="logo" src="./././g10.png" /> */}

          {/* <div className="banner_images"> */}
          <div className="bannerdescription">
            {/* <h3 className='banner_titles_small'>Requests Detail</h3> */}
            <h2 className="banner_titles_large">Requests Detail</h2>
            <h2 className="banner_titles_large">
              # {singleEntry.request_number}{" "}
            </h2>
            {/* <p className="banner_text">We're here to help!</p> */}
          </div>
          {/* <img className="process_images_inner"  style={{ height:'300px' }} alt="logo" src="./././1122.png" /> */}
          {/* </div> */}
        </div>

        <div
          className="addRequestFormConatiner mobileDetail"
          style={{ width: "90%", marginTop: "-14px", marginBottom: "30px" }}
        >
          <figure>
            {/* <img src="./././faq.png" alt=""></img> */}
            {type === "admin" && (
              <div className="update_button">
                <button
                  type="submit"
                  onClick={
                    uploadedFile !== null ? uploadFile : editRequestsAdmin
                  }
                  disabled={loading}
                >
                  {loading ? (
                    <Bars color="#ffffff" height={20} width={20} />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            )}
          </figure>

          <div
            className="mainDetail"
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <section className="root">
              <h2 className="trackingtitle">Tracking Detail</h2>
              <div className="order-track">
                <div className="order-track-step">
                  <div className="order-track-status">
                    <span
                      className={`order-track-status-dot ${
                        singleEntry.search_status === "pending" ||
                        singleEntry.search_status === "completed" ||
                        singleEntry.search_status === "in_progress"
                          ? "status-reached"
                          : ""
                      }`}
                    ></span>
                    <span className="order-track-status-line"></span>
                  </div>
                  <div className="order-track-text">
                    <p className="order-track-text-stat">Request Submitted</p>
                    <span className="order-track-text-sub">
                      {formatDate(singleEntry.created_time)}
                    </span>
                  </div>
                </div>
                <div className="order-track-step">
                  <div className="order-track-status">
                    <span
                      className={`order-track-status-dot ${
                        singleEntry.search_status === "in_progress" ||
                        singleEntry.search_status === "completed"
                          ? "status-reached"
                          : ""
                      }`}
                    ></span>
                    <span className="order-track-status-line"></span>
                  </div>
                  <div className="order-track-text">
                    <p className="order-track-text-stat"> In progress </p>
                    {/* <span class="order-track-text-sub">1st November, 2019</span> */}
                  </div>
                </div>
                <div className="order-track-step">
                  <div className="order-track-status">
                    <span
                      className={`order-track-status-dot ${
                        singleEntry.search_status === "completed"
                          ? "status-reached"
                          : ""
                      }`}
                    ></span>
                    <span className="order-track-status-line"></span>
                  </div>
                  <div className="order-track-text">
                    <p className="order-track-text-stat"> Completed</p>
                    {/* <span className="order-track-text-sub">3rd November, 2019</span> */}
                  </div>
                </div>
              </div>
            </section>
            {/* <h2> # {singleEntry.request_number }</h2> */}
            <div className="detail-section contractorinfo">
              <h2>Request Detail</h2>

              <h3>
                Contractor Name :{" "}
                <span>{singleEntry.contractor_name || placeholder}</span>
              </h3>
              <h3>
                License Number :{" "}
                <span> {singleEntry.lic_number || placeholder}</span>
              </h3>
              <h3>
                City/Country : <span> {singleEntry.city || placeholder}</span>
              </h3>
              <h3>
                License Type :<span>{singleEntry.lic_type || placeholder}</span>
              </h3>
              <h3>
                Requested By :
                <span> {singleEntry.user_name || placeholder}</span>
              </h3>
              <h3>
                Requester Email :
                <span> {singleEntry.user_email || placeholder}</span>
              </h3>
              {singleEntry.doc_url != undefined &&
                singleEntry.doc_url != "" && (
                  <h3>
                    Requester uploaded doc:
                    {/* <span>   */}
                      <a
                        href={singleEntry.doc_url}
                        style={{ textAlign: "center" }}
                        download
                        target="_blank"
                      >
                        {" "}
                      </a>
                      <img
                        src="./././fileicon.png"
                        alt="PDF Icon"
                        style={{ width: "32px", height: "32px" }}
                      />
                     {/* <h5>{singleEntry.uploadedText || "File"}</h5>  */}
                    {/* </span> */}
                  </h3>
                )}
            </div>
            {type === "user" && (
              <div className="detail-section contractorinfo">
                <h2>Contractor Info</h2>
                <h3>
                  Sunbiz Registration:{" "}
                  <span>{singleEntry.sunbiz_registration || placeholder}</span>
                </h3>
                <h3>
                  Licensure Information:{" "}
                  <span>{singleEntry.licensure_info || placeholder}</span>
                </h3>
                <h3>
                  BBB Rating:{" "}
                  <span>{singleEntry.bbb_rating || placeholder}</span>
                </h3>
                <h3>
                  Owner Info:{" "}
                  <span>{singleEntry.owner_information || placeholder}</span>
                </h3>
                <h3>
                  Google Reviews:{" "}
                  <span>{singleEntry.google_reviews || placeholder}</span>
                </h3>
                <h3>
                  Social Account:{" "}
                  <span>{singleEntry.social_media_account || placeholder}</span>
                </h3>
                <h3>
                  Insurance:{" "}
                  <span>
                    {singleEntry.insurance_information || placeholder}
                  </span>
                </h3>
              </div>
            )}
          </div>
         <h3 style={{textAlign:"center"}}>Admin Uploaded Documents</h3>
          <div className="mobileadminuploaded"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
           
           
            {mediaData.length > 0 ? (
              mediaData.map((file, index) => (
                <div key={index} style={{ margin: "0 10px" }}>
                  <a
                    href={file.file_url}
                    style={{ textAlign: "center" }}
                    download
                    target="_blank"
                  >
                    <img
                      src="./././fileicon.png"
                      alt="PDF Icon"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <h5>{getFileNameFromUrl(file.file_url)}</h5>
                  </a>
                </div>
              ))
            ) : (
              <h3>No files are yet uploaded by admin</h3>
            )}
          </div>
          {type === "admin" && (
            <div className="adminsection">
              <div
                className="status_file"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "10px" }}>
                  Status:
                  <select
                    style={{
                      padding: "14px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      backgroundColor: "#fff",
                      fontSize: "12px",
                      cursor: "pointer",
                      outline: "none",
                      minWidth: "200px",
                    }}
                    onChange={statusChange}
                    value={statusSearch}
                    required
                  >
                    <option value="pending">Pending</option>
                    <option value="in_progress">In Progress</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
                <div>
                  <input
                    type="file"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="detail-section contractorinfo">
                <h2>Contractor Info</h2>

                <form className="contractorinfor" onSubmit={editRequestsAdmin}>
                  <div className="main_group">
                    <div className="form-group">
                      <label htmlFor="sunbiz">Sunbiz Registration:</label>
                      <input
                        type="text"
                        id="sunbiz"
                        value={sunbiz}
                        onChange={(e) => setSunbiz(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="licInfo">Licensure Information:</label>
                      <input
                        type="text"
                        id="licInfo"
                        value={lic_info}
                        onChange={(e) => setLicInfo(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="bbbRating">BBB Rating:</label>
                      <input
                        type="text"
                        id="bbbRating"
                        value={bbbRating}
                        onChange={(e) => setBbbRating(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="insurance">Insurance:</label>
                      <input
                        type="text"
                        id="insurance"
                        value={insurance}
                        onChange={(e) => setInsurance(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="main_group">
                    <div className="form-group">
                      <label htmlFor="ownerInfo">Owner Info:</label>
                      <input
                        type="text"
                        id="ownerInfo"
                        value={ownerInfo}
                        onChange={(e) => setOwnerInfo(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="googleReview">Google Reviews:</label>
                      <input
                        type="text"
                        id="googleReview"
                        value={googleReview}
                        onChange={(e) => setGoogleReview(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="socialAccount">Social Account:</label>
                      <input
                        type="text"
                        id="socialAccount"
                        value={socialAccount}
                        onChange={(e) => setSocialAccount(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div style={{ position: "relative", marginBottom: "80px" }}>
                <TextEditor
                  statusSearch={statusSearch}
                  editRequestsAdmin={editRequestsAdmin}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function formatDate(timestampInSeconds) {
  // Convert timestamp to milliseconds
  const timestampInMilliseconds = timestampInSeconds * 1000;

  // Create a new Date object using the timestamp in milliseconds
  const date = new Date(timestampInMilliseconds);

  // Format the date however you want
  const formattedDate = `${date.getFullYear()}-${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;

  // Format the time
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return `${formattedDate} ${formattedTime}`;
}

export default RequestDetail;
