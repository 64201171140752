import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='contentContainer'style={{ padding: '35px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Privacy Policy</h1>
      
    <h3>1. Introduction</h3>
    <p>We are committed to protecting your privacy. This policy explains how we collect, use, and disclose the personal information you provide to us through our website.</p>

    <h3>2. Information Collection</h3>
    <p>We may collect the following information:</p>
    <ul>
      <li>Name and job title</li>
      <li>Contact information including email address</li>
      <li>Demographic information such as postcode, preferences, and interests</li>
      <li>Other information relevant to customer surveys and/or offers</li>
    </ul>

    <h3>3. Use of Information</h3>
    <p>We use the information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
    <ul>
      <li>Internal record keeping.</li>
      <li>We may use the information to improve our products and services.</li>
      <li>We may periodically send promotional emails about new products, special offers, or other information which we think you may find interesting using the email address which you have provided.</li>
    </ul>

    <h3>4. Security</h3>
    <p>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>

    <h3>5. Cookies</h3>
    <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. We use cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs.</p>
        </div>
    );
}

export default PrivacyPolicy;
