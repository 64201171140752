// Home.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsProgress, faCheckCircle, faDumpsterFire, faThumbsUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Header from './Header';
import { GET_FAX_REQUESTS_ADMIN, GET_REQUESTS_STATS }  from '../config/api-config'
import {Bars} from 'react-loader-spinner';

const AdminHome = () => {
const navigate = useNavigate();
const [tableData, setTableData] = useState([]);
const placeholder="----";
const [statsCount, setStatsCount] = useState({
all:0,
pending:0,
in_progress:0,
completed:0
}
);
const [loading, setLoading] = useState(false);
const token = localStorage.getItem("token");

const getStats = async () => {
  setLoading(true);
  try {
    const response = await axios.get(GET_REQUESTS_STATS, {
      headers: { 
        'authorization': token
      },
    });
    if(response.status === 200){
      if(response.data.status === 1){
        setStatsCount(response.data.data)
      }else if(response.data.status === 10){
        setLoading(false);
        alert(response.data.message)
        navigate("/");
      }else{
        setLoading(false);
        console.log(response.data.message);
        // alert(response.data.message)
      }
    }else{
      setLoading(false);
      alert("Error in Logging in , Please try again")
    }
    // Handle the response as needed
  } catch (error) {
    // Handle errors here
    console.error('Error signing up:', error.response.data);
  } finally {
    setLoading(false);
  }
};
useEffect(() => {

  if(!token || token.length === 0){
    navigate("/");
  }else{
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(GET_FAX_REQUESTS_ADMIN, {
        headers: { 
          'authorization': token
        },
      });
      if(response.status === 200){
        if(response.data.status === 1){
          setTableData(response.data.data);
        }else if(response.data.status === 10){
          setLoading(false);
          alert(response.data.message)
          navigate("/");
        }else{
          setLoading(false);
          console.log(response.data.message);
          // alert(response.data.message)
        }
      }else{
        setLoading(false);
        alert("Error in Logging in , Please try again")
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors here
      console.error('Error signing up:', error.response.data);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
  getStats();
}
}, []);
const handleRowClick = (rowId) => {
  navigate("/requestDetail?request_id="+rowId);
  // Add your logic here for what should happen when the row is clicked
};

  const handleAddRequest = () => {
    // Add logic for handling the addition of a request
    navigate("/addrequest");
  };
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Sample data for the table
  // const tableData = [
  //   { id: 1, title: '#393HJHYT', date: '22 Dec, 2023' },
  //   { id: 2, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 3, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 4, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 5, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 6, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 7, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 8, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 9, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 10, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 11, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 12, title: '#393HJHYT', date: '22 Dec, 2023' },
  //   { id: 13, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   { id: 14, title: '#393HJHYT', date: '22 Dec, 2023' },
  //   { id: 15, title: '#393HJHYT',  date: '22 Dec, 2023' },
  //   // Add more data as needed
  // ];

  return (
    <div>
      {/* Header */}
    <Header />
    {loading && (
        <div className="loader-container">
          <Bars color="#1976d2"  loading={loading} height="40" width="40"/>
        </div>
      )}
    <div className='contentContainer'style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' , marginTop:'84px'}}>
      {/* Add Request Button */}
      <div className="process_images_container" style={{backgroundImage:`url('./././backgroundone.png')`, height:'300px', backgroundSize:'cover'}} >
    {/* <img className="process_images_inner_first"  style={{ height:'250px'}} alt="logo" src="./././g10.png" /> */}

   
    {/* <div className="banner_images"> */}
    <div className='bannerdescription'><h3 className='banner_titles_small'>Howdy Admin</h3>
    <h1 className="banner_titles_large">Empower Users to </h1>
    <h1 className="banner_titles_large"> Discover Best Contractors</h1>
    <p className="banner_text">Lets go!</p>
    </div>
    {/* <img className="process_images_inner"  style={{ height:'300px' }} alt="logo" src="./././1122.png" /> */}
    {/* </div> */}
    </div>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        {/* <button onClick={handleAddRequest}>Add Request <FontAwesomeIcon style={{marginRight: '5px', color: '#ffffff'}} icon={faPlus} /> */}
      <div className='cards'>
        <div className='card'>
          <div className='card-icon'><FontAwesomeIcon icon={faThumbsUp} color='#1976d2' /></div>
          <div className='card-content'>
            <div className='card-title'>All</div>
            <div className='card-count'>{statsCount.all}</div>
          </div>
        </div>
        <div className='card'>
          <div className='card-icon'><FontAwesomeIcon icon={faCheckCircle} color='#1976d2' /></div>
          <div className='card-content'>
            <div className='card-title'>Completed</div>
            <div className='card-count'>{statsCount.completed}</div>
          </div>
        </div>
        <div className='card'>
          <div className='card-icon'><FontAwesomeIcon icon={faTimesCircle} color='#1976d2' /></div>
          <div className='card-content'>
            <div className='card-title'>Pending</div>
            <div className='card-count'>{statsCount.pending}</div>
          </div>
        </div>
        <div className='card'>
          <div className='card-icon'><FontAwesomeIcon icon={faBarsProgress} color='#1976d2' /></div>
          <div className='card-content'>
            <div className='card-title'>In Progress</div>
            <div className='card-count'>{statsCount.in_progress}</div>
          </div>
        </div>
      </div>
{/* </button> */}
      <h3>User Requests</h3>
      </div>

     {/* Material-UI Table */} 
     <div className="tableContainer" style={{ width: '80%' }}>
     <TableContainer component={Paper} style={{ marginTop: '20px', border:'solid 1px #0303' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: '5px' }}>Request Number</TableCell>
                <TableCell style={{ padding: '5px' }}>Contractor Name</TableCell>
                <TableCell style={{ padding: '5px' }}>License Number</TableCell>
                <TableCell style={{ padding: '5px' }}>License Type</TableCell>
                <TableCell style={{ padding: '5px' }}>City/Country</TableCell>
                <TableCell style={{ padding: '5px' }}>Status</TableCell>
                <TableCell style={{ padding: '5px' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {(rowsPerPage > 0
                  ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : tableData
                ).map((row) => (
                  <TableRow key={row.id} className="hover-row" onClick={() => handleRowClick(row.id)}>
                    <TableCell style={{ padding: '5px' }}>#{row.request_number}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{row.contractor_name || placeholder}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{row.lic_number || placeholder}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{row.lic_type || placeholder}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{row.city || placeholder}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{row.search_status || placeholder}</TableCell>
                    <TableCell style={{ padding: '5px', color:"red" }}>  <FontAwesomeIcon style={{ color: 'red',textAlign:'center'}} icon={faDumpsterFire} />
</TableCell>
                  </TableRow>
                ))}
              </TableBody>
          </Table>
        </TableContainer>

       <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </div>
        </div>
    </div>
  );
};

export default AdminHome;
